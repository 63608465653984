import React from 'react';
import Link from "gatsby-link";
import {Col} from "react-bootstrap";

function Languagescategory({data}) {

    return (
        <>
            {data?.map((value, i) => (
                <Col xs={12} md={3} lg={3} xl={3} xxl={3}>
                    <div className="cateorgy_namee">
                        <h4>{value.name}</h4>
                        <div className="menu_items">
                            {value?.languages?.data?.map((item, i) => (
                                <li>
                                    <Link to={`/language/${item.slug}`}>{item.name}</Link>
                                </li>
                            ))}
                        </div>
                    </div>
                </Col>
            ))}


        </>
    );
}

export default Languagescategory;